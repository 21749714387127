import './style.scss'

const FeatureAndPreference = () => {
    return (
        <>
            Hi I am Feature and all
        </>
    )
}

export default FeatureAndPreference