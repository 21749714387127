export const CounsellorData = [
  {
    _id: 1,
    month: 'January',
    counsellorGain: 2458,
    counsellorLost: -256
  },
  {
    _id: 1,
    month: 'Febraury',
    counsellorGain: 1000,
    counsellorLost: -25
  },
  {
    _id: 1,
    month: 'March',
    counsellorGain: 4523,
    counsellorLost: -1023
  },
  {
    _id: 1,
    month: 'April',
    counsellorGain: 5023,
    counsellorLost: -128
  },
  {
    _id: 1,
    month: 'May',
    counsellorGain: 2356,
    counsellorLost: -500
  },
  {
    _id: 1,
    month: 'June',
    counsellorGain: 2365,
    counsellorLost: -254
  },
  {
    _id: 1,
    month: 'July',
    counsellorGain: 1023,
    counsellorLost: -128
  },
  {
    _id: 1,
    month: 'August',
    counsellorGain: 7856,
    counsellorLost: -1254
  },
  {
    _id: 1,
    month: 'September',
    counsellorGain: 10236,
    counsellorLost: -2569
  },
  {
    _id: 1,
    month: 'October',
    counsellorGain: 2365,
    counsellorLost: -254
  },
  {
    _id: 1,
    month: 'November',
    counsellorGain: 5023,
    counsellorLost: -128
  },
  {
    _id: 1,
    month: 'December',
    counsellorGain: 5023,
    counsellorLost: -128
  },
]

export const UserData = [
  {
    _id: 1,
    month: 'January',
    userGain: 2458,
    userLost: -256
  },
  {
    _id: 1,
    month: 'Febraury',
    userGain: 2569,
    userLost: -25
  },
  {
    _id: 1,
    month: 'March',
    userGain: 1254,
    userLost: -1023
  },
  {
    _id: 1,
    month: 'April',
    userGain: 6985,
    userLost: -236
  },
  {
    _id: 1,
    month: 'May',
    userGain: 500,
    userLost: -1032
  },
  {
    _id: 1,
    month: 'June',
    userGain: 125,
    userLost: -1280
  },
  {
    _id: 1,
    month: 'July',
    userGain: 12,
    userLost: -1282
  },
  {
    _id: 1,
    month: 'August',
    userGain: 1258,
    userLost: -458
  },
  {
    _id: 1,
    month: 'September',
    userGain: 1254,
    userLost: -128
  },
  {
    _id: 1,
    month: 'October',
    userGain: 100,
    userLost: -65
  },
  {
    _id: 1,
    month: 'November',
    userGain: 900,
    userLost: -10
  },
  {
    _id: 1,
    month: 'December',
    userGain: 798,
    userLost: -656
  },
]

export const ActivityData = [
  {
    _id: 1,
    day: "01-01-2024",
    onlineUsers: 2458,
    onlineCounsellors: 102
  },
  {
    _id: 2,
    day: "01-02-2024",
    onlineUsers: 3000,
    onlineCounsellors: 200
  },
  {
    _id: 3,
    day: "01-03-2024",
    onlineUsers: 3000,
    onlineCounsellors: 200
  },
  {
    _id: 4,
    day: "01-04-2024",
    onlineUsers: 300,
    onlineCounsellors: 20
  },
  {
    _id: 5,
    day: "01-05-2024",
    onlineUsers: 5000,
    onlineCounsellors: 2000
  },

]

export const EarningsData = [
  {
    _id: 1,
    month: 'January',
    earning: 15478
  },
  {
    _id: 1,
    month: 'February',
    earning: 14589
  },
  {
    _id: 1,
    month: 'March',
    earning: 17589
  },
  {
    _id: 1,
    month: 'April',
    earning: 15584
  },
  {
    _id: 1,
    month: 'May',
    earning: 19586
  },
  {
    _id: 1,
    month: 'June',
    earning: 20658
  },
  {
    _id: 1,
    month: 'July',
    earning: 21547
  },
  {
    _id: 1,
    month: 'August',
    earning: 32569
  },
  {
    _id: 1,
    month: 'September',
    earning: 40325
  },
  {
    _id: 1,
    month: 'October',
    earning: 25698
  },
  {
    _id: 1,
    month: 'November',
    earning: 53698
  },
  {
    _id: 1,
    month: 'December',
    earning: 102458
  },
]